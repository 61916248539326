import { forEach, omit } from 'lodash';
import Router from 'next/router';
import querystring from 'querystring';
import { SEARCH_PAGE } from '@core/ui/navigation/constant';

export function createUrl(urlData, json = false) {
  const keys = Object.keys(urlData);
  let search = json ? {} : '?';
  keys.forEach(key => {
    if (
      urlData[key] !== null &&
      urlData[key] !== '' &&
      key !== 'page' &&
      key !== 'limit'
    ) {
      if (json) {
        search[key] = urlData[key];
      } else {
        search += `${key}=${urlData[key]}&`;
      }
    }
  });
  return json ? search : search.substring(0, search.length - 1);
}

export function getUrl() {
  const location = process.browser && window.location;
  const data =
    process.browser && location.search
      ? location.search.slice(location.search.indexOf('?') + 1).split('&')
      : [];
  const urlData = {};
  data.forEach(data => {
    try {
      data = data.split('=');
      const dataVal = decodeURIComponent(data[1]);
      urlData[data[0]] = dataVal;
    } catch (e) {}
  });
  return urlData;
}

export function setUrl(urlData) {
  const search = createUrl(urlData);
  if (Router && location.pathname + search !== location.pathname) {
    Router.push(location.pathname + search);
  }
}

export function clearUrl(url = null) {
  Router.push(url || location.pathname);
}

export function setStateToUrl(state) {
  let urlData = getUrl();
  urlData = { ...urlData, ...state };
  setUrl(cleanParams(urlData));
}

export function cleanParams(state) {
  let params = {};
  for (const key in state) {
    if (state.hasOwnProperty(key)) {
      switch (key) {
        case 'text':
          params[key] = state[key] ? state[key] : null;
          break;
        case 'category':
          break;
        case 'categories':
          params[key] =
            state[key] && state[key].length ? state[key].join() : null;
          break;
        case 'price':
          params[key] =
            state[key] && state[key].length ? state[key].join() : null;
          break;
        case 'location':
          if (state[key] && state[key].lat) {
            params[`${key}_lat`] = state[key].lat;
          }
          if (state[key] && state[key].lng) {
            params[`${key}_lng`] = state[key].lng;
          }
          break;
        case 'subInfos':
          const _subInfos = state[key];
          let _subInfosToString = '';
          if (typeof _subInfos === 'object') {
            forEach(_subInfos, (v, k) => {
              _subInfosToString += k + '|' + v + ',';
            });
          } else if (typeof _subInfos === 'string') {
            _subInfosToString = _subInfos;
          }
          params[key] = _subInfosToString === '' ? null : _subInfosToString;
          break;
        default:
          params[key] = encodeURIComponent(state[key]);
          break;
      }
    }
  }
  return params;
}

export function getBlobalParams(filters) {
  const paramsObject = {};
  if (filters.text) {
    paramsObject['text'] = filters.text;
  }
  if (filters.town) {
    paramsObject['town'] = filters.town;
  }
  if (filters.priceMax) {
    paramsObject['priceMax'] = filters.priceMax;
  }
  if (filters.priceMin) {
    paramsObject['priceMin'] = filters.priceMin;
  }
  return paramsObject;
}

export function getUrlToState(category) {
  const urlData = getUrl();

  const state = { page: 1, limit: 24 };
  for (const key in urlData) {
    if (urlData.hasOwnProperty(key)) {
      switch (key) {
        case 'additionalType':
          const value = urlData[key];
          if (value === 'pro' || value === 'private') {
            state[key] = value;
          }
          break;
        case 'text':
          state[key] =
            urlData[key] && urlData[key] !== 'null' ? urlData[key] : '';
          break;
        case 'town':
          state[key] = urlData[key];
          break;
        case 'priceMin':
          state[key] = urlData[key];
          break;
        case 'priceMax':
          state[key] = urlData[key];
          break;
        case 'category':
          state[key] = urlData[key];
          break;
        case 'subInfos':
          state[key] = urlData[key];
          break;
        case 'page':
          if (urlData[key]) {
            state['page'] = Number(urlData[key]);
          }
          break;
        case 'limit':
          if (urlData[key]) {
            state['limit'] = Number(urlData[key]);
          }
          break;
      }
    }
  }
  if (category) {
    state['category'] = category;
  }
  return state;
}

export function slugify(string = '') {
  if (typeof string !== 'string') {
    return null;
  }
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function getSidFromSlug(slug) {
  if (typeof slug === 'string') {
    const params = slug.split('-');
    return params[params.length - 1];
  }
  return '??';
}

export function getAlertsUrl(data) {
  let { category } = data;
  const clonedData = omit(data, ['category']);
  let categorySlug = '';
  if (category) {
    categorySlug = '/' + slugify(category);
  } else {
    categorySlug = '/all';
  }

  const qs = querystring.stringify(clonedData);
  const href = `${window.location.origin}/${SEARCH_PAGE}${categorySlug}${
    qs ? `?${qs}` : ''
  }`;
  return { href };
}
