export const HOME_PAGE = '';
export const HOME_VIEW_PAGE = 'home';
export const SEARCH_PAGE = 'annonces';
export const NEAREST_POST_PAGE = 'nearest-posts';
export const RECENT_POST_PAGE = 'recent-posts';
export const FAVOURITE_PAGE = 'favorites';

export const ACCOUNT_PAGE = 'profile';
export const USER_FAVOURITE_POST = 'favourite-post';
export const USER_DRAFT_POST = 'draft-post';

export const CATEGORIES_PAGE = 'categories';
export const SINGLE_CATEGORY_PAGE = 'category';

export const SINGLE_POST_PAGE = 'annonces';

export const ADMOB_VIEW = 'AdmobView';

export const SIGNIN_PAGE = 'signin';
export const SIGNUP_PAGE = 'signup';
export const SIGNUP_MOBILE_PAGE = 'SignupMobilePage';
export const FORGET_PASSWORD_PAGE = 'forget-password';
export const RESET_PASSWORD_PAGE = 'reset-password';

export const ADD_POST = 'add-post';
export const SEARCH_ARCHIVE_PAGE = 'SearchArchivePage';

export const ACCOUNT_SETTING_PAGE = 'account-settings';

export const CGU = 'cgu';
export const MENTIONS_LEGALES = 'mentions-legales';
export const ABOUT = 'about';

export const MESSAGERIE = 'messagerie';
export const ALERTS = 'alerts';
export const ALERT = 'alert';
